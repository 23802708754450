<template>
  <b-modal id="add-input" title="Add new farm input" hide-footer>
    <b-container fluid>
      <b-form v-show="!loading" @submit.prevent="add_input" @reset="onReset">
        <input-form
          :form="form"
          :categories="categories"
          :errors="errors"
        ></input-form>
      </b-form>
      <div v-show="loading" class="text-center justify-content">
        <b-spinner variant="primary"></b-spinner>
        <div>Adding Input...</div>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import { axiosApiInstance } from "@/plugins/axios";
import InputForm from "../input_form/InputForm.vue";

export default {
  components: { InputForm },
  props: {
    categories: {
      type: Array,
      default: () => {
        return [];
      },
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    projectId: {
      type: Number,
      default: () => {
        return 1;
      },
    },
  },
  data() {
    return {
      form: {
        category: null,
        name: "",
        unit_quantity_per_acre: [null],
        units: [null],
        price: [null],
      },
      loading: false,
      errors: {
        name: "",
      },
    };
  },
  methods: {
    add_input() {
      let data = this.form;
      if (!this.isExists(data)) {
        this.loading = true;
        const baseURI = process.env.VUE_APP_BACKEND;
        axiosApiInstance
          .post(baseURI + `/api/v3/inputs/`, data, {
            params: { project_id: this.projectId },
          })
          .then((result) => {
            this.message = result.data.message;
            this.loading = false;
            this.$bvModal.hide("add-input");
            this.onReset();
            this.$emit("AddInputChanged");
            
          })
          .catch((error) => {
            this.loading = false;
            this.$emit("AddInputChanged");
            this.error = error;
          });
      } else {
        this.errors.name = {
          message: `Input ${data.name} already exists in category ${data.category}`,
        };
      }
    },

    onReset() {
      (this.form.category = null),
        (this.form.name = ""),
        (this.form.units = []),
        (this.form.price = []),
        (this.form.unit_quantity_per_acre = []),
        (this.unitRows.length = 0);
      this.errors.name = "";
      this.$bvModal.hide("add-input");
    },
    isExists(formObj) {
      let inputExists;
      if (formObj["category"] !== "Cash") {
        inputExists = this.items.filter(
          (item) =>
            item["category"] === formObj["category"] &&
            item["name"].toLowerCase() === formObj["name"].toLowerCase()
        );
        return inputExists.length > 0 ? true : false;
      } else {
        inputExists = this.items.filter(
          (item) => item["category"] === formObj["category"]
        );
        return inputExists.length > 0 ? true : false;
      }
    },
  },

  mounted() {},
};
</script>

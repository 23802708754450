<template>
  <!-- view input details modal -->
  <b-modal id="view-details" size="sm" hide-footer hide-header>
    <h5 class="text-center">Farm Input Details</h5>
    <div class="details-header">
      <h6 class="text-center">{{ selectedItem.name }}</h6>
      <p class="text-center">{{ selectedItem.category }}</p>
    </div>
    <div>
      <template>
        <div>
          <b-table :items="detailItems" :fields="fieldItems"></b-table>
        </div>
      </template>
    </div>
    <div>
      <template>
        <div>
          <b-table :items="availableStock"></b-table>
        </div>
      </template>
    </div>
    <div>
      <b-button
        variant="outline-danger"
        class="float-left"
        size="sm"
        v-analytics:click="[
          'delete_input_via_details',
          { element: 'Settings' },
        ]"
        v-b-modal.delete-input
      >
        <b-icon-trash></b-icon-trash>
        Delete Input
      </b-button>
      <b-button
        v-b-modal.edit-input
        variant="outline-primary"
        class="float-right"
        size="sm"
        >Edit Input</b-button
      >
    </div>
    <edit-input
      @EditInputChanged="reload"
      :selectedItem="selectedItem"
      :categories="categories"
      :items="items"
      :projectId="projectId"
    ></edit-input>
    <delete-input
      @DeleteChanged="reload"
      :selectedItem="selectedItem"
      :projectId="projectId"
    ></delete-input>
  </b-modal>
</template>
<script>
import DeleteInput from "../delete_input/Delete.vue";
import EditInput from '../edit_input/EditInput.vue'

export default {
  props: {
    selectedItem: {
      type: Object,
      default: () => {
        return {
          units: [],
          unit_quantity_per_acre: [],
        };
      },
    },
    categories: {
      type: Array,
      default: () => {
        return [];
      },
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    projectId: {
      type: Number,
      default: () => {
        return 1;
      },
    },
  },
  data() {
    return {
      fieldItems: ["Product Packages", "Price"],
    };
  },
  computed: {
    detailItems() {
      let items = [];
      for (let i = 0; i < this.selectedItem.units.length; i++) {
        items.push({
          "Product Packages": this.selectedItem.units[i],
          Price: this.selectedItem.price[i],
        });
      }
      return items;
    },
    availableStock() {
      let availableStock = [];
      for (let i = 0; i < this.selectedItem.units.length; i++) {
        availableStock.push({
          Package: this.selectedItem.units[i],
          "Stock(Units)": this.selectedItem.unit_quantity_per_acre[i],
        });
      }
      return availableStock;
    },
  },
  methods: {
    details() {},
    reload() {
      this.$emit("ViewInputChanged");
    },
  },
  components: { DeleteInput, EditInput },
};
</script>

<template>
  <b-modal id="delete-input" size="sm" hide-footer hide-header>
    <h5 class="text-center">Delete Input</h5>
    <p class="text-center">
      Do you want to delete this item?<br />You will not be able to recover it.
    </p>
    <div >
      <b-button
        type="reset"
        variant="secondary"
        class="center float-left"
        size="sm"
        v-analytics:click="['cancel_input_delete', { element: 'Settings' }]"
        @click="close"
        >Cancel</b-button
      >
      <b-button
        type="submit"
        variant="danger"
        class="ml-2 float-right"
        size="sm"
        :disabled="isDeleting"
        v-analytics:click="['confirm_input_delete', { element: 'Settings' }]"
        @click="deleteInput()"
      >
        <b-spinner v-if="isDeleting"  small label="Small Spinner"></b-spinner>
        <b-icon-trash v-if="!isDeleting"  small label="Small Spinner"></b-icon-trash>
        Yes, Delete
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import { axiosApiInstance } from "@/plugins/axios";
export default {
  props: {
      projectId:{
          type:Number,
          default:()=>{
              return 1
          }
      },
      selectedItem:{
          type:Object,
          default:()=>{
              return {}
          }
      }
  },
  data(){
      return {
          isDeleting:false,
      }
  },
  methods: {
    close() {
      this.$bvModal.hide("delete-input");
    },
    deleteInput() {
      this.isDeleting = true;
      const baseUrl = process.env.VUE_APP_BACKEND;
      const inputCategory = this.selectedItem.category;
      const inputName =  this.selectedItem.name;
      axiosApiInstance
        .delete(
          baseUrl +
            `/api/v3/inputs/${this.projectId}/${inputCategory}/${inputName}/delete`
        )
        .then(() => {
          this.isDeleting = false;
          this.$emit('DeleteChanged')
          this.$bvModal.hide("delete-input");
          this.$bvModal.hide("view-details");
        })
        .catch((error) => {
          this.isDeleting = false;
          this.$emit('DeleteChanged')
          this.error = error;
          this.$vToastify.error("Something went wrong please try again");
        });
    },
  },
  mounded() {},
};
</script>

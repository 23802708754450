<template>
  <b-modal id="edit-input" title="Edit Input" hide-footer>
    <b-container fluid>
      <b-form v-show="!loading" @submit="edit_input" @reset="onReset">
        <input-form
          :form="selectedItem"
          :categories="categories"
          :errors="errors"
        ></input-form>
      </b-form>
      <div v-show="loading" class="text-center justify-content">
        <b-spinner variant="primary"></b-spinner>
        <div>Saving Input...</div>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import { axiosApiInstance } from "@/plugins/axios";
import InputForm from "../input_form/InputForm.vue";

export default {
  components: { InputForm },
  props: {
    selectedItem: {
      type: Object,
      default: () => {
        return {
          category: null,
          name: "",
          unit_quantity_per_acre: [],
          units: [],
          price: [],
        };
      },
    },
    categories: {
      type: Array,
      default: () => {
        return [];
      },
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    projectId: {
      type: Number,
      default: () => {
        return 1;
      },
    },
  },
  data() {
    return {
      errors: {
        name: "",
      },
      loading: false,
    };
  },
  methods: {
    edit_input(e) {
      e.preventDefault();
      let data = this.selectedItem;

      this.loading = true;
      const baseURI = process.env.VUE_APP_BACKEND;
      axiosApiInstance
        .put(baseURI + `/api/v3/inputs/`, data, {
          params: { project_id: this.projectId },
        })
        .then((result) => {
          this.message = result.data.message;
          this.loading = false;
          this.onReset();
          this.$emit("EditInputChanged");
          this.$bvModal.hide("edit-input");
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("AddInputChanged");
          this.error = error;
        });
    },
    onReset() {
      this.$bvModal.hide("edit-input");
    },
  },

  mounted() {},
};
</script>

<template>
  <div class="container-fluid">
    <h3 class="font-weight-bold">Farm Inputs</h3>
    <hr class="mt-0 pl-1 mb-2" style="background-color: #e2e2e2" />
    <div class="d-flex">
        <b-select text="All Categories" class="m-md-2" style="width:150px"
            v-analytics:change="['filter_input_category',{element:'Settings'}]"
            v-model="selectedCategory" @change="filterByCategory" variant="light">
            <b-select-option selected
              value="All">All Categories</b-select-option>
            <b-select-option
              v-for="(category,idx) in categoriesList"
              :key="'d' + idx" :value="category"
              >{{ category }}</b-select-option
            >
          </b-select>
      <b-form inline>
        <b-input-group prepend="Search" class="mb-2 mr-sm-2 mb-sm-0">
          <b-form-input
            placeholder="Search Name"
            type="search"
            v-model="searchfield"
            v-analytics:keyup="['search_input_name',{element:'Settings'}]"
            @keyup="searchByName"
          ></b-form-input>
        </b-input-group>
      </b-form>
    </div>
    <b-row>
      <b-col cols="9">
        <div  v-show="loading" class="justify-center text-center pt-3">
          
          <b-spinner  variant="primary"></b-spinner>
          <div>Loading Inputs...</div>
        </div>
        <b-table
          :fields="fields"
          :items="items"
          v-show="!loading"
          thead-class="bg-primary-tb text-primary-tb border-primary-tb"
          class="text-capitalize"
        >
        <template #cell(units)="data">
          <b-list-group v-for="(item, index) in data.item.units" :key="index" :item="item">
          <b-list-group-item >
            {{ item }}
          </b-list-group-item>
        </b-list-group>
          </template>
          <template #cell(price)="data">
          <b-list-group v-for="(item, index) in data.item.price" :key="index" :item="item">
          <b-list-group-item >
            {{ item }}
          </b-list-group-item>
        </b-list-group>
          </template>
          <template #cell(unit_quantity_per_acre)="data">
          <b-list-group v-for="(item, index) in data.item.unit_quantity_per_acre" :key="index" :item="item">
          <b-list-group-item >
            {{ item }}
          </b-list-group-item>
        </b-list-group>
          </template>
          <template #cell(actions)="data">
            <div>
            <b-dropdown id="dropdown-1" variant="outline-secondary" no-caret>
              <template #button-content>
                <b-icon-three-dots-vertical
                  aria-hidden="true"
                ></b-icon-three-dots-vertical>
              </template>
                <b-dropdown-item variant="primary"
                v-b-modal.view-details
                v-analytics:click="['view_input_details',{element:'Settings'}]"
                @click="view_details(data.item)"
                >
                  View Details
                </b-dropdown-item>
              <b-dropdown-item
                variant="default"
                v-b-modal.edit-input
                @click="view_details(data.item)"
                >Edit</b-dropdown-item>
              <b-dropdown-item
                variant="danger"
                v-b-modal.delete-input
                v-analytics:click="['delete_input',{element:'Settings'}]"
                @click="view_details(data.item)">
                Delete</b-dropdown-item>
            </b-dropdown>
          </div>
          </template>
        </b-table>
      </b-col>
      <b-col>
          <b-button class="w-75 mb-2" variant="primary" 
          v-analytics:click="['add_new_input',{element:'Settings'}]"
          v-b-modal.add-input>
              <b-icon-plus></b-icon-plus> Add Farm Input
          </b-button>
          <b-button class="w-75 mb-2" variant="outline-primary" v-show="false">
              <b-icon-plus></b-icon-plus> Import from CSV
          </b-button>
          <b-button class="w-75" variant="outline-primary" v-show="false">
              <b-icon-plus></b-icon-plus> Import from Agrishop
          </b-button>
          <div class="mt-5">
          <div class="d-flex flex-column border-bottom pb-2 w-75 " v-if="items.some(item => item.category === 'Cash')">
            <div class="d-flex align-items-center my-2">
              <div>Enable Cash Advances</div>
              <b-form-checkbox name="check-button" switch class="ml-auto" v-model="checkCashAdvances" 
              v-analytics:change="['enable_cash_advances',{element:'Settings'}]"
              @change="toggleCashAdvances">
              </b-form-checkbox>
            </div>
            <div class="d-flex align-items-center my-2">
              <div>Enable Interest on advances</div>
              <b-form-checkbox name="check-button" switch class="ml-auto" :disabled="!checkCashAdvances" 
              v-analytics:change="['enable_advances_interest',{element:'Settings'}]"
              v-model="checkInterestAdvances">
              </b-form-checkbox>
            </div>
            <div class="d-flex align-items-center my-2">
              <div>Interest on advances</div>
                <b-input class="col-3 ml-auto"  v-model="cashInterestValue" :disabled="!checkInterestAdvances" @mouseout="addInterest"></b-input>
            </div>
          </div>
    </div>
    <delete-input @DeleteChanged="reload" :selectedItem="selectedItem" :projectId="projectId"></delete-input>
    <view-input @ViewInputChanged="reload" :categories="categories" :items="items" :selectedItem="selectedItem" :projectId="projectId"></view-input>
    <add-input @AddInputChanged="reload" :categories="categories" :items="items" :projectId="projectId"></add-input>
    <edit-input @EditInputChanged="reload" :selectedItem="selectedItem" :categories="categories" :items="items" :projectId="projectId"></edit-input>
      </b-col>

    </b-row>
    
    
  
  </div>
</template>
]<script>
import axios from "axios";
import DeleteInput from './components/delete_input/Delete.vue'
import AddInput from './components/add_input/AddInput.vue'
import ViewInput from './components/view_input/ViewInput.vue'
import EditInput from './components/edit_input/EditInput.vue'
import { axiosApiInstance } from '@/plugins/axios';

export default {
  components:{
    DeleteInput,
    ViewInput,
    AddInput,
    EditInput,
  },
  data() {
    let searchfield = "";
    let fields = [
      "name",
      { key: "units", label: "Unit" },
      { key: "price", label: "Unit Price" },
      { key: "unit_quantity_per_acre", label: "Stock Amount" },
      { key: "category", label: "Category" },
      "actions",
    ];
    let items = [];
    let categoriesList = [];
    let selectedUnits;
    let selectedPrice;
    let selectedQuantity;
    let isChecked = localStorage.getItem('toggle-cash-advances')
    return {
      fields,
      items,
      searchfield,
      
      form: {
        category: null,
        name: "",
        unit_quantity_per_acre: [],
        units: [],
        price: [],
      },
      
      categories: [{text: "Select Category", value: null}, "Services", "Farm tools", "Fertilizer", "Pesticide", "Herbicide", "Seed", "Cash"],
      
      detailItems: [],
      availableStock: [],
      loading: false,
      categoriesList,
      selectedCategory: "All",
      price: [],
      selectedUnits,
      selectedPrice,
      selectedQuantity,
      checkCashAdvances: isChecked,
      filteredItems: [],
      checkInterestAdvances: false,
      cashInterestValue: "",
      isDeleting: false,
      selectedItem: {
        units: [],
        unit_quantity_per_acre: [], 
      },
    };
  },
  mounted: function(){
    this.reload();
    this.fetchInputStatus(this);
    this.$analytics.track("open_input_settings",{element:"Settings"});
  },
  computed: {
    projectId(){
      return this.$store.state.project_id;
    }
  },
  methods: {
    reload(){
      this.fetchData();
      this.getCategories();
    },
    view_details(item) {
        this.selectedItem = item;
    },

    fetchInputStatus(dataObj) {
      const baseURI = process.env.VUE_APP_BACKEND
      dataObj.parameters = {
          project_id: dataObj.projectId
        };
        axios
          .get(baseURI + '/advances/get-inputs-status', {
            headers:{'Authorization': 'Bearer ' + localStorage.getItem("access_token")},
            params: dataObj.parameters
          })
          .then((result) => {
            if(result.data.success){
              dataObj.checkCashAdvances = result.data.data[0].status === "active" ? true : false;
              dataObj.checkInterestAdvances = (result.data.data[0].interest > 0 && result.data.data[0].status === "active") ? true : false;
              dataObj.cashInterestValue = result.data.data[0].interest ? result.data.data[0].interest.toString().concat("%"): null;
            }
          })
          .catch(error => {
            console.log(error);
          })
    },
    
    searchByName(){
      if(this.searchfield.length !== 0){
        this.items = this.filteredItems.filter(
          item => item['name'].toLowerCase().includes(
            this.searchfield.toLowerCase()));
      } else {
        this.items = this.filteredItems;
      }
    },
      filterByCategory(){
      if(this.selectedCategory !== 'All'){
        this.items = this.filteredItems.filter(item => {
          return item.category.toLowerCase().includes(this.selectedCategory.toLowerCase())
      })
      } else {
        this.items = this.filteredItems;
      }
    },
    toggleCashAdvances(e) {
      localStorage.setItem('toggle-cash-advances', e)
      const baseURI = process.env.VUE_APP_BACKEND
      const headers = {
        'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    axios
    .put(baseURI + `/advances/toggle-cash-status`, {status: e }, {
              headers: headers,
              params: {project_id: this.projectId}
          })
    .then((result) => {
        this.message = result.data.message;
        this.fetchInputStatus(this);
      })
    .catch(error => {
        this.error = error;
      })
    },
    addInterest() {
      const baseURI = process.env.VUE_APP_BACKEND
      const headers = {
        'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
      axios
        .put(baseURI + `/advances/toggle-cash-advance-interest`, {interest: this.cashInterestValue.replace("%", "") }, {
            headers: headers,
            params: {project_id: this.projectId}
        })

      .then((result) => {
          this.message = result.data.message;
          this.fetchInputStatus(this);
        })
      .catch(error => {
          this.error = error;
        })
    },
    fetchData(){
      this.loading=true
      const baseURI = process.env.VUE_APP_BACKEND
      let parameters = {
          project_id: this.projectId
        };
      axiosApiInstance
        .get(baseURI + '/api/v3/inputs/', {
          params: parameters
        })
        .then((result) => {
          if(result.data.success){
            this.items = result.data.data;
            this.filteredItems = result.data.data;
            this.loading=false;
          }
        })
        .catch(error => {
          console.log(error);
          this.loading=false;
        })
      },
    
    
    
    getCategories() {
      const baseURI = process.env.VUE_APP_BACKEND
      axios
        .get(baseURI + '/api/v3/inputs/categories', {
          headers:{'Authorization': 'Bearer ' + localStorage.getItem("access_token")},
          params: {project_id: this.projectId}
        })
        .then((result) => {
          if (result.data.success) {
            this.categoriesList = result.data.data;
          }
        })
        .catch(error => {
          this.error = error;
        })
    },
  },
};
</script>

<style>
.error {
    color: red;
}
@media (min-width: 576px) {
    #delete-input {
        margin: 20rem auto;
    }
    #view-details {
        margin: 8rem auto;
    }
    .details-header {
       margin: 10px 0;
    }
}
</style>

  
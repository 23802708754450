<template>
  <b-row>
    <div class="container-fluid">
      <b-form-group label="Input Category" label-for="Input Category">
        <b-form-select
          v-model="form.category"
          required
          :options="categories"
          @change="updateForm"
        ></b-form-select>
        <p class="error" v-if="this.form.category === 'Cash' && errors.name">
          {{ errors.name["message"] }}
        </p>
      </b-form-group>
      <b-form-group
        label="Input Name"
        label-for="Input Name"
        v-if="this.form.category !== 'Cash'"
      >
        <b-form-input
          v-model="form.name"
          type="text"
          required
          @keydown="updateForm"
        ></b-form-input>
        <p class="error" v-if="errors.name">
          {{ errors.name["message"] }}
        </p>
      </b-form-group>
      <b-form-row>
        <b-col>
          <b-form-group
            label="Available Amount"
            label-for="Unit Quantity Per Acre"
          >
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Units/Packages" label-for="Units">
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Price Per Unit"
            label-for="Price Per Unit"
            v-if="this.form.category !== 'Cash'"
          >
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-group>
        <b-form-row
          v-for="(unitRow, index) in form.unit_quantity_per_acre"
          :key="index"
        >
          <b-col>
            <b-form-group>
              <b-form-input
                v-model="form.unit_quantity_per_acre[index]"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-form-select
                v-model="form.units[index]"
                :options="units"
                required
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col style="display:flex;">
            <b-form-group>
              <b-form-input
                align-v="center"
                v-model="form.price[index]"
                type="number"
                required
                placeholder="UGX"
                class="w-95"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <b-icon-x
               v-show="index!==0"
                class="h3 mb-0"
                variant="secondary"
                @click="removeUnitRow(index)"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-button
          variant="link"
          class="text-decoration-none"
          v-analytics:click="['add_input_package', { element: 'Settings' }]"
          @click="addUnitRow"
          v-if="this.form.category !== 'Cash'"
        >
          <b-icon-plus></b-icon-plus> Package
        </b-button>
      </b-form-group>
      <div>
        <b-button
          type="reset"
          variant="outline-primary"
          class="float-left"
          v-analytics:click="['cancel_add_input', { element: 'Settings' }]"
          >Cancel</b-button
        >
        <b-button
          type="submit"
          variant="primary"
          v-analytics:click="['save_new_input', { element: 'Settings' }]"
          class="float-right"
          :disabled="loading"
        >
          <b-spinner v-if="loading" small label="Small Spinner"></b-spinner>
          Save
        </b-button>
      </div>
    </div>
  </b-row>
</template>
<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => {
        return [];
      },
    },
    form: {
      type: Object,
      default: () => {
        return {
          category: null,
          name: "",
          unit_quantity_per_acre: [],
          units: [],
          price: [],
        };
      },
    },
    errors: {
      type: Object,
      default: () => {
        return {
          name: "",
        };
      },
    },
  },
  data() {
    return {
      unitRows: [],
      nextUnitRowId: this.form.price.length,
      loading: false,
      units: [
        { text: "Select Unit", value: null },
        "1 Kg",
        "500g",
        "50g",
        "10g",
        "500mg",
        "250mg",
        "1 Litre",
        "500ml",
        "250ml",
        "Piece",
        "Hr",
        "Min",
        "Sec",
        "UGX",
      ],
    };
  },
  methods: {
    updateForm() {
      if (this.form.category === "Cash") {
        (this.form.name = ""),
          (this.form.units = []),
          (this.form.price = []),
          (this.form.unit_quantity_per_acre = []);
        this.unitRows.length = 0;
      }
      return this.errors.name ? (this.errors.name = "") : null;
    },
    removeUnitRow(id) {
      this.unitRows.filter((el) => el.id !== id);
      this.unitRows.pop();
      this.selectedUnits = [...this.form.units];
      this.selectedPrice = [...this.form.price];
      this.selectedQuantity = [...this.form.unit_quantity_per_acre];
      this.selectedUnits.splice(id, 1);
      this.selectedPrice.splice(id, 1);
      this.selectedQuantity.splice(id, 1);
      this.form.units = this.selectedUnits;
      this.form.price = this.selectedPrice;
      this.form.unit_quantity_per_acre = this.selectedQuantity;
    },
    addUnitRow() {
      this.form.units.push(null);
      this.form.price.push(null);
      this.form.unit_quantity_per_acre.push(null);
    },
  },
  mounted() {},
};
</script>
